@charset "UTF-8";
/**
IMPORTANTE: gulp non compila più app.scss. I nuovi stili vanno ora scritti su public/css/frontend.css
 */
@font-face {
  font-family: "FontAwesome";
  src: url(../fonts/fontawesome-webfont.ttf) format("truetype");
}

html, body {
  font-size: 14px;
  color: #333;
  min-height: 100%;
}

section {
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  padding-bottom: 1rem;
  color: inherit;
  line-height: 1em;
  margin: 0;
  text-transform: uppercase;
}

h1 {
  font-size: 3.5rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  text-transform: initial;
  font-size: 2rem;
}

h4 {
  font-size: 2.4rem;
}

h5 {
  font-size: 1.6rem;
}

a {
  color: inherit;
}

a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
}

p {
  margin: 0;
  padding-bottom: 1rem;
}

#main-container.main-container {
  margin-bottom: 28px;
}

#body {
  background-size: 100% auto;
  min-height: 100vh;
}

.am_button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
}

#page_container {
  width: 100%;
  padding: 0;
  max-width: 1425px;
  margin-left: auto;
  margin-right: auto;
}

.dataTable {
  font-size: .9rem;
}

.dataTable tbody tr td .container-fluid {
  padding: 0 !important;
}

[v-cloak] {
  display: none;
}

.slick_carousel {
  overflow: hidden;
  position: relative;
}

.slick_carousel .slick_carousel_image {
  height: 100%;
  background: #fff;
}

.slick_carousel .slick_carousel_image img {
  max-height: 500px;
}

.slick_carousel .slick-slide img {
  display: inline;
}

.slick_carousel .slick_carousel_video {
  height: 500px;
}

.slick_carousel .slick_carousel_video iframe.video_frame {
  width: 100%;
  height: 100%;
}

.slick_carousel .slick-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  width: 5rem;
  height: 5rem;
  padding: 0;
  margin: 0;
  border: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.5) !important;
  opacity: 1;
  line-height: 5rem;
  font-size: 2rem;
  text-align: center;
  text-shadow: none;
  transition: all 300ms ease 0ms;
  -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: 0 0 7px rgba(0, 0, 0, 0.075);
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.075);
  z-index: 20;
}

.slick_carousel .slick-arrow.slick-arrow-left {
  left: .5rem;
}

.slick_carousel .slick-arrow.slick-arrow-right {
  right: .5rem;
}

.slick_carousel .slick-arrow:hover {
  background: white !important;
}

.dt_media_preview_box {
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: relative;
}

.dt_media_preview_box .dt_media_preview {
  display: inline-block;
  vertical-align: top;
  height: 70px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #fff;
  z-index: 3;
  position: relative;
}

.dt_media_preview_box .dt_media_preview img {
  vertical-align: top;
  height: 100%;
}

.dt_media_preview_box .dt_media_nomedia {
  width: 70px;
  height: 35px;
  line-height: 35px;
  font-size: .9em;
  background-color: #fff;
  z-index: 3;
  position: relative;
  font-style: italic;
  text-align: center;
}

.dt_media_preview_box .dt_media_preview_options {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background-color: #eee;
  white-space: normal;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  transition: all 300ms ease 0ms;
}

.dt_media_preview_box .dt_media_preview_options button, .dt_media_preview_box .dt_media_preview_options a {
  display: inline-block;
  vertical-align: top;
  padding: 5px !important;
  height: 35px;
  line-height: 25px !important;
}

.dt_media_preview_box .dt_media_preview_options button:hover, .dt_media_preview_box .dt_media_preview_options a:hover {
  background-color: #ddd !important;
}

.dt_media_preview_box:hover .dt_media_preview_options {
  right: -22px;
}

.dt_media_preview_box.with_media .dt_media_nomedia {
  display: none;
}

.dt_media_preview_box:not(.with_media) .dt_media_preview {
  display: none;
}

.dt_media_preview_box:not(.with_media) .dt_media_preview_options a.media-download {
  display: none;
}

@media screen and (max-width: 768px) {
  .slick_carousel .slick_carousel_image img {
    max-height: 65vw;
  }
  .slick_carousel .slick_carousel_video {
    height: 65vw;
  }
}

/***************************/
/*          Login          */
/***************************/
body.login-layout .widget-box {
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1) !important;
  background-color: #fff !important;
}

body.login-layout .widget-box .widget-body {
  background-color: #fff;
}

body.login-layout .widget-box .widget-body .widget-main {
  background-color: #fff;
}

#login_menu_user {
  width: 100%;
  padding: .5rem 1rem .25rem 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#login_menu_user #login_menu_user_icon i {
  vertical-align: middle;
  font-size: 2em;
  margin-right: 1rem;
}

#login_menu_user #login_menu_username {
  font-weight: 800;
  font-size: 1.2rem;
}

/***************************/
/*        Dashboard        */
/***************************/
#new_news_banner {
  width: 100%;
  padding: .3rem 1rem;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #333;
}

#new_news_banner #new_news_banner_icon {
  margin-right: 1rem;
  position: relative;
}

#new_news_banner #new_news_banner_icon #new_news_banner_notify {
  position: absolute;
  bottom: -10%;
  left: -20%;
  display: inline-block;
  vertical-align: top;
  background-color: #fff;
  width: 1rem;
  height: 1rem;
  text-align: center;
  font-size: .8rem;
  font-weight: 700;
  border-radius: .5rem;
  line-height: 1.2;
}

#new_news_banner #new_news_banner_text {
  font-weight: 700;
  text-transform: uppercase;
}

#dashboard_header {
  padding: 3.5rem 1rem 1rem 1rem;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

#dashboard_header #dashboard_header_stripe1 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  align-content: center;
}

#dashboard_header #dashboard_header_stripe1 #header_logo {
  display: inline-block;
  vertical-align: top;
  width: 20.07142857rem;
  margin-left: 4rem;
}

#dashboard_header #dashboard_header_stripe1 #login_btn_wrapper {
  position: relative;
}

#dashboard_header #dashboard_header_stripe1 #login_btn_wrapper #admin_btn {
  padding: 10px 15px 10px 10px;
  font-weight: 800;
  font-size: 1.28rem;
}

#dashboard_header #dashboard_header_stripe1 #login_btn_wrapper #login_btn {
  display: inline-block;
  padding: 10px 15px 10px 10px;
  font-weight: 800;
  font-size: 1.28rem;
}

#dashboard_header #dashboard_header_stripe1 #login_btn_wrapper #login_btn i {
  vertical-align: middle;
  font-size: 2.2em;
  margin: -5px 5px 0 0;
}

#dashboard_header #dashboard_header_stripe1 #login_btn_wrapper #login_menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  min-width: 250px;
  margin: 0;
  border: 0;
  background-color: #fff;
  -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
}

#dashboard_header #dashboard_header_stripe1 #login_btn_wrapper #login_menu #login_menu_options {
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  border-bottom: 1px solid #eee;
}

#dashboard_header #dashboard_header_stripe1 #login_btn_wrapper #login_menu #login_menu_options button, #dashboard_header #dashboard_header_stripe1 #login_btn_wrapper #login_menu #login_menu_options a {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding: .5rem 0;
}

#dashboard_header #dashboard_header_stripe1 #login_btn_wrapper #login_menu #login_menu_achievements {
  padding: 1rem;
}

#dashboard_header #dashboard_header_stripe1 #login_btn_wrapper #login_menu #login_menu_achievements #login_menu_achievements_title {
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: .5rem;
}

#dashboard_header #dashboard_header_stripe1 #login_btn_wrapper #login_menu #login_menu_achievements .login_menu_section .login_menu_btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#dashboard_header #dashboard_header_stripe1 #login_btn_wrapper #login_menu #login_menu_achievements .login_menu_section .login_menu_btn .login_menu_btn_icon {
  width: 3rem;
  padding: .5rem .5rem .5rem 0;
  box-sizing: border-box;
}

#dashboard_header #dashboard_header_stripe1 #login_btn_wrapper #login_menu #login_menu_achievements .login_menu_section .login_menu_btn .login_menu_btn_icon .login_menu_btn_icon_badge {
  display: inline-block;
  vertical-align: top;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  background-color: #eee;
  background-size: cover;
  background-position: center center;
  line-height: 2.5;
  text-align: center;
}

#dashboard_header #dashboard_header_stripe1 #login_btn_wrapper #login_menu #login_menu_achievements .login_menu_section .login_menu_btn .login_menu_btn_label {
  width: calc(100% - 3rem);
  padding: .5rem;
  box-sizing: border-box;
  font-weight: 800;
}

#dashboard_header #dashboard_main_slide {
  background-size: cover;
  background-position: top center;
}

#dashboard_header #dashboard_main_slide #dashboard_main_slide_inner {
  height: 35rem;
  background-image: linear-gradient(180deg, rgba(12, 12, 12, 0.51) 0%, rgba(255, 255, 255, 0.13) 100%);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

#dashboard_header #dashboard_main_slide #dashboard_main_slide_inner #dashboard_main_slide_claim {
  padding: 0 5vw;
}

#dashboard_header #dashboard_main_slide #dashboard_main_slide_inner #dashboard_main_slide_claim h2, #dashboard_header #dashboard_main_slide #dashboard_main_slide_inner #dashboard_main_slide_claim h4 {
  color: #fff;
  text-shadow: 0em 0.1em 0.1em rgba(0, 0, 0, 0.4);
}

#dashboard {
  background-color: #fff;
}

#dashboard #last_challenge_container .last_challenge_banner {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
  width: 100%;
  position: relative;
}

#dashboard #last_challenge_container .last_challenge_banner:not(:last-child)::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 5.5rem;
  width: calc(100% - 11rem);
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
}

#dashboard #last_challenge_container .last_challenge_banner .last_challenge_banner_link {
  width: calc(100% - 3rem);
  padding: 1rem 0 1rem 5.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

#dashboard #last_challenge_container .last_challenge_banner .last_challenge_banner_link .last_challenge_banner_badge {
  align-self: flex-start;
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  text-align: center;
  line-height: 4;
  color: #fff;
}

#dashboard #last_challenge_container .last_challenge_banner .last_challenge_banner_link .last_challenge_banner_label {
  margin-left: 1rem;
  color: #fff;
  text-shadow: 0em 0.1em 0.1em rgba(0, 0, 0, 0.4);
}

#dashboard #last_challenge_container .last_challenge_banner .last_challenge_banner_link .last_challenge_banner_label .last_challenge_banner_title {
  font-weight: 700;
  text-transform: uppercase;
}

#dashboard #last_challenge_container .last_challenge_banner .last_challenge_banner_link .last_challenge_banner_label .last_challenge_banner_title .chip {
  display: inline-block;
  vertical-align: bottom;
  padding: 0 7px;
  background-color: #fff;
  border-radius: 3px;
  text-shadow: none;
  box-shadow: 0em 0.1em 0.1em rgba(0, 0, 0, 0.1);
  margin-left: .5rem;
}

#dashboard #last_challenge_container .last_challenge_banner .last_challenge_banner_link .last_challenge_banner_label .last_challenge_banner_date {
  font-style: italic;
  margin-bottom: .5rem;
}

#dashboard #last_challenge_container .last_challenge_banner .last_challenge_banner_close {
  width: 3rem;
  text-align: center;
  color: #fff;
}

#dashboard #dashboard_slider {
  background-color: #605f64;
  margin-bottom: 4rem;
}

#dashboard #dashboard_slider .dashboard_slider_slide {
  height: 15rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  vertical-align: top;
}

#dashboard #dashboard_icons_menu_wrapper {
  padding: 3.85rem 0;
  background-color: #f7f7f7;
  background-size: cover;
  background-position: top left;
}

#dashboard #dashboard_icons_menu_wrapper #dashboard_icons_menu_container {
  margin: 0 5.5%;
  padding-bottom: 4.78rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#dashboard #dashboard_icons_menu_wrapper #dashboard_icons_menu_container .dashboard_menu_icons_box {
  display: block;
  width: 20%;
  text-align: center;
  font-size: 1.7rem;
  margin-top: 2.78rem;
  padding: 2rem 0;
  border-radius: 0px;
  color: #605f64;
  text-shadow: 3px 0 #fff, 0 3px #fff, -3px 0 #fff, 0 -3px #fff;
}

#dashboard #dashboard_icons_menu_wrapper #dashboard_icons_menu_container .dashboard_menu_icons_box.disabled {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  background-color: transparent !important;
  cursor: default;
}

#dashboard #dashboard_icons_menu_wrapper #dashboard_icons_menu_container .dashboard_menu_icons_box i {
  font-size: 7rem;
  margin-bottom: 2rem;
}

#dashboard #dashboard_icons_menu_wrapper #dashboard_icons_menu_container .dashboard_menu_icons_box:hover {
  color: #605f64;
}

#dashboard #dashboard_area_menu {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
}

#dashboard #dashboard_area_menu .dashboard_area_menu_box {
  display: block;
  background-size: cover;
  background-position: top center;
  height: 26rem;
  position: relative;
  flex-grow: 1;
}

#dashboard #dashboard_area_menu .dashboard_area_menu_box .dashboard_area_menu_title {
  position: absolute;
  top: 70%;
  left: 3%;
  right: 0;
  width: 94%;
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 2rem;
  font-weight: 400;
  color: #fff;
}

#dashboard #dashboard_area_menu .dashboard_area_menu_box.disabled {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

@media screen and (max-width: 1024px) and (min-width: 541px) {
  html, body {
    font-size: 1.3671875vw;
  }
  #dashboard #dashboard_area_menu .dashboard_area_menu_box .dashboard_area_menu_title {
    top: unset;
    bottom: 0;
  }
}

@media screen and (max-width: 1024px) {
  #dashboard #dashboard_header {
    padding-left: 0;
    padding-right: 0;
  }
  #dashboard #dashboard_header #dashboard_header_stripe1 #header_logo {
    margin: 0 2rem;
    width: 15rem;
  }
}

@media screen and (max-width: 540px) {
  html, body {
    font-size: 2.6vw;
  }
  #dashboard #dashboard_icons_menu_wrapper #dashboard_icons_menu_container .dashboard_menu_icons_box {
    width: 50%;
  }
  #dashboard #dashboard_area_menu {
    flex-wrap: wrap;
  }
  #dashboard #dashboard_area_menu .dashboard_area_menu_box {
    width: 100%;
  }
}

/***************************/
/*        Content          */
/***************************/
#header_container {
  background-size: auto;
  background-position: center center;
}

#header_container.product_header_container {
  background-size: contain;
  background-repeat: repeat;
}

#header_container #header {
  height: 15rem;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.3) 100%);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#header_container #header h1 {
  width: 100%;
  padding: 0 3rem;
  box-sizing: border-box;
  color: #ffffff;
  text-shadow: 0em 0.1em 0.1em rgba(0, 0, 0, 0.4);
  text-align: center;
}

#header_container #header .subtitle {
  color: #fff;
  margin-top: 10px;
}

#header_container #header .header_execution_score_wrapper {
  margin-top: 1rem;
  width: 250px;
  box-shadow: 0em 0.1em 0.1em rgba(0, 0, 0, 0.4);
}

#header_container #header .header_execution_score_wrapper .header_execution_score_bar {
  width: 100%;
  margin-top: .3rem;
  background-color: #fff;
  position: relative;
  text-align: center;
  font-size: .8rem;
  transition: all 300ms ease 0ms;
}

#header_container #header .header_execution_score_wrapper .header_execution_score_bar .header_execution_score_progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding-left: .3rem;
}

#header_container #header .header_execution_score_wrapper .header_execution_score_bar .product_execution_score_number {
  position: relative;
}

.nb_breadcrumb {
  background: #fff;
  padding: 1% 5%;
}

.nb_breadcrumb a, .nb_breadcrumb span {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 800;
  margin-right: 20px;
}

.nb_breadcrumb a:not(:last-child)::after, .nb_breadcrumb span:not(:last-child)::after {
  content: "\f054";
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  margin-left: 20px;
  margin-bottom: 5px;
  font-size: .9rem;
  display: inline-block;
  vertical-align: middle;
  color: #333;
}

.box_text {
  padding: 15px;
  line-height: 1.7;
  letter-spacing: .5px;
}

.box_text h1, .box_text h2, .box_text h3, .box_text h4, .box_text h5, .box_text h6 {
  padding-top: 1rem;
}

.box_text table {
  width: 100% !important;
  height: auto !important;
}

.box_text table thead, .box_text table tbody, .box_text table tr, .box_text table td, .box_text table th {
  /*width:auto !important;
      height:auto !important;*/
}

.box_text table.layout_table {
  width: calc(100% + 2rem) !important;
  margin-left: -1rem;
  table-layout: fixed;
}

.box_text table.layout_table tr td {
  padding: 1rem;
  vertical-align: top;
}

.box_text table.content_table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

.box_text table.content_table thead tr {
  background-color: #f5bf6a;
}

.box_text table.content_table thead tr th {
  padding: .5rem;
  text-align: center;
  border: 2px solid #fff;
  text-transform: uppercase;
  color: #fff;
  line-height: 1.4;
  font-weight: 800;
}

.box_text table.content_table tbody tr:nth-child(2n+1) {
  background-color: #d9d9d9;
}

.box_text table.content_table tbody tr:nth-child(2n) {
  background-color: #e6e6e6;
}

.box_text table.content_table tbody tr td, .box_text table.content_table tbody tr th {
  padding: .5rem;
  text-align: center;
  border: 2px solid #fff;
  line-height: 1.4;
}

.box_text table.content_table tbody tr th {
  background-color: #f5bf6a;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  font-weight: 800;
}

.box_text img {
  /*width:auto !important;
    height:auto !important;*/
  vertical-align: top;
}

.box_text .box_image {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  margin-bottom: 1rem;
}

.box_text .box_image img {
  width: 100% !important;
}

.box_text .box_image .box_image_caption {
  font-size: 1.2rem;
  font-weight: 400;
  padding: .1rem .5rem;
  text-align: center;
  text-transform: uppercase;
}

.box_text .box_caption {
  max-width: 21.4rem;
  height: 15rem;
  margin: 0 auto 1rem auto;
  background-color: #a6a6a6;
  color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.box_text .box_caption .box_caption_caption {
  display: inline-block;
  vertical-align: middle;
  font-size: 2.2rem;
  font-weight: 800;
  max-width: 100%;
  padding: .1rem .5rem;
  box-sizing: border-box;
  text-align: center;
}

@media screen and (max-width: 540px) {
  .box_text table.layout_table {
    display: block !important;
  }
  .box_text table.layout_table tbody {
    display: block !important;
  }
  .box_text table.layout_table tbody tr {
    display: block !important;
  }
  .box_text table.layout_table tbody tr td {
    display: block !important;
  }
}

/***************************/
/*        Category         */
/***************************/
#category_wrapper {
  background-color: #fff;
  background-size: auto auto;
  background-position: top center;
}

#category_wrapper #category_return {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding: .5rem 1.5rem;
  box-sizing: border-box;
  color: #fff;
  font-weight: 800;
  text-align: left;
}

#category_wrapper #filter_by_brand_selector {
  text-align: center;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: #f7f7f7;
}

#category_wrapper #filter_by_brand_selector #filter_by_brand_label {
  text-transform: uppercase;
  font-weight: 800;
  margin: .5rem 1rem;
}

#category_wrapper #filter_by_brand_selector #filter_by_brand_select a.filter_by_brand_link {
  display: inline-block;
  vertical-align: middle;
  padding: .5rem 1rem;
}

#category_wrapper #filter_by_brand_selector #filter_by_brand_select a.filter_by_brand_link:hover {
  background-color: #eee;
}

#category_wrapper #filter_by_brand_selector #filter_by_brand_select a.filter_by_brand_link img {
  height: 2.1rem;
}

#category_wrapper #category_body {
  padding: 5%;
}

.product_execution_score_wrapper .product_execution_score_bar {
  width: 100%;
  padding: .1rem 0;
  margin-top: .3rem;
  background-color: #eee;
  position: relative;
  text-align: center;
  font-size: 0rem;
  transition: all 300ms ease 0ms;
}

.product_execution_score_wrapper .product_execution_score_bar .product_execution_score_progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding-left: .3rem;
}

.product_execution_score_wrapper .product_execution_score_bar .product_execution_score_number {
  position: relative;
  z-index: 2;
}

ul#category_subcategory_list {
  list-style: none;
  margin: -2.5%;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

ul#category_subcategory_list li.category_subcategory_li {
  width: 28.33333%;
  margin: 2.5%;
}

ul#category_subcategory_list li.category_subcategory_li a.category_subcategory_link {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 22rem;
  position: relative;
  background-size: cover;
  background-position: center center;
  transition: transform 300ms ease 0ms, transform 300ms ease 0ms, transform 300ms ease 0ms, transform 300ms ease 0ms, transform 300ms ease 0ms, border-radius 300ms ease 0ms, filter 300ms ease 0ms, filter 300ms ease 0ms;
  /*&:hover{*/
}

ul#category_subcategory_list li.category_subcategory_li a.category_subcategory_link.disabled {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

ul#category_subcategory_list li.category_subcategory_li a.category_subcategory_link .subcategory_box_title {
  position: absolute;
  top: 70%;
  left: 3%;
  right: 0;
  width: 94%;
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 2rem;
  font-weight: 400;
  color: #fff;
}

ul#category_subcategory_list li.category_subcategory_li a.product_list_link {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 22rem;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 2;
  transition: transform 300ms ease 0ms, transform 300ms ease 0ms, transform 300ms ease 0ms, transform 300ms ease 0ms, transform 300ms ease 0ms, border-radius 300ms ease 0ms, filter 300ms ease 0ms, filter 300ms ease 0ms;
}

ul#category_subcategory_list li.category_subcategory_li a.product_list_link:hover {
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

ul#category_subcategory_list li.category_subcategory_li a.product_list_link.disabled {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

ul#category_subcategory_list li.category_subcategory_li .product_execution_score_wrapper {
  height: 0;
}

ul#category_subcategory_list li.category_subcategory_li:hover .product_execution_score_wrapper .product_execution_score_bar {
  font-size: .8rem;
}

ul#category_subcategory_list li.category_subcategory_li .product_list_title {
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
  z-index: 3;
  position: relative;
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  ul#category_subcategory_list li.category_subcategory_li {
    width: 45%;
  }
}

@media screen and (max-width: 540px) {
  #category_wrapper #category_body ul#category_subcategory_list {
    margin-left: -5%;
    margin-right: -5%;
  }
  #category_wrapper #category_body ul#category_subcategory_list li.category_subcategory_li {
    width: 95%;
  }
}

/***************************/
/*        Sections         */
/***************************/
#sections_container {
  background-color: #f2f2f2;
}

#sections_loader_wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#sections_loader_wrapper #sections_loader {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  font-weight: 800;
}

#sections_loader_wrapper #sections_loader i {
  margin-right: 5px;
  vertical-align: middle;
}

/***************************/
/*       User stats        */
/***************************/
/***************************/
/*        Product          */
/***************************/
#product_data_sheet {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
}

#product_data_sheet #product_menu_column {
  display: block;
  width: 225px;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 10;
  -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
}

#product_data_sheet #product_menu_column #product_menu_fixed {
  position: fixed;
  top: auto;
  left: auto;
  width: 225px;
  padding: 4rem 3.25rem 3.25rem 3.25rem;
  box-sizing: border-box;
  background-color: transparent;
  z-index: 11;
}

#product_data_sheet #product_menu_column #product_menu_fixed img#product_menu_logo {
  width: 100%;
}

#product_data_sheet #product_menu_column #product_menu_fixed button.navbar-toggle {
  background-color: transparent;
  margin: 0;
}

#product_data_sheet #product_menu_column #product_menu_fixed .product_page_return {
  /*position:absolute;
        top:0;
        left:0;*/
  display: inline-block;
  vertical-align: top;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  font-weight: 700;
  /*background-color:$color_primary;
        color:#fff;*/
  margin-top: .5rem;
}

#product_data_sheet #product_menu_column #product_menu_fixed .navbar-collapse {
  padding: 0;
}

#product_data_sheet #product_menu_column #product_menu_fixed .navbar-collapse ul#product_menu {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 3.25rem;
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: 800;
  color: #666666;
}

#product_data_sheet #product_menu_column #product_menu_fixed .navbar-collapse ul#product_menu li.product_menu_item {
  margin-bottom: 10px;
}

#product_data_sheet #product_menu_column #product_menu_fixed .navbar-collapse ul#product_menu li.product_menu_item button {
  width: 100%;
  text-align: left;
  text-transform: uppercase;
}

#product_data_sheet #product_menu_column #product_menu_fixed .navbar-collapse ul#product_menu li.product_menu_item button:hover {
  color: #333;
  background-color: transparent;
}

#product_data_sheet #product_body {
  width: calc(100% - 225px);
  background-color: #f2f2f2;
}

#product_data_sheet #product_body #product_breadcrumb_container {
  background-color: #fff;
  padding: 4rem 2rem 1rem 2rem;
  text-align: right;
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: 800;
  color: #666666;
}

#product_data_sheet #product_body .tab-content {
  padding: 0;
  border: 0;
}

#product_data_sheet #product_body .tab-content .product_tab .product_title_stripe {
  text-align: center;
  padding: 2rem;
  color: #fff;
  font-weight: 800;
}

.product_tab_template1 {
  padding: 1.5%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.product_tab_template1 .product_tab_template1_boxleft {
  width: 42%;
  margin: 1.5%;
  align-self: flex-start;
}

.product_tab_template1 .product_tab_template1_boxleft:not(.transparent) {
  background-color: #fff;
}

.product_tab_template1 .product_tab_template1_boxright {
  width: 52%;
  margin: 1.5%;
}

.product_tab_template1 .product_tab_template1_boxright:not(.transparent) {
  background-color: #fff;
}

.product_tab_template1 .product_tab_template1_boxright video {
  height: 340px;
}

.product_tab_template2 {
  padding: 1.5%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.product_tab_template2 .product_tab_template1_boxleft {
  width: 33%;
  margin: 1.5%;
  align-self: flex-start;
}

.product_tab_template2 .product_tab_template1_boxleft:not(.transparent) {
  background-color: #fff;
}

.product_tab_template2 .product_tab_template1_boxright {
  width: 61%;
  margin: 1.5%;
}

.product_tab_template2 .product_tab_template1_boxright:not(.transparent) {
  background-color: #fff;
}

.product_tab_template2 .product_tab_template1_boxright .video_frame {
  width: 100%;
  height: 460px;
}

.product_tab_box {
  width: 97%;
  margin: 1.5%;
}

.product_tab_box:not(.transparent) {
  background-color: #fff;
}

.product_tab_box .video_frame {
  width: 100%;
  height: 635px;
}

#training_module_wrapper .product_tab_box img {
  height: auto !important;
}

.tab_section_loader {
  padding: 3rem;
  text-align: center;
}

.tab_section_loader i {
  margin-right: 5px;
  vertical-align: middle;
}

.product_retail_book {
  width: 100%;
  height: 700px;
}

.stats_general {
  padding: 15px;
  background-color: #fff;
}

.stats_items_container {
  padding: 15px;
}

.stats_items_container .stats_item_box {
  background-color: #fff;
  margin-bottom: 15px;
  padding: 15px;
}

.stats_items_container .stats_item_box h5 {
  text-align: center;
}

.stats_items_container .stats_item_box .quiz_stats_box {
  margin-top: 15px;
  padding: 10px;
  background-color: #f7f7f7;
}

.stat_attributes .stat_attribute_row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
}

.stat_attributes .stat_attribute_row:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.stat_attributes .stat_attribute_row .stat_attribute_title {
  width: 50%;
  padding: 7px 15px;
  box-sizing: border-box;
}

.stat_attributes .stat_attribute_row .stat_attribute_value {
  width: 50%;
  padding: 7px 15px;
  box-sizing: border-box;
  font-weight: 800;
}

.stat_attributes .stat_attribute_row .stat_attribute_value > .stat_attribute_value_item:not(:first-child) {
  padding-top: 1rem;
}

.stat_attributes .stat_attribute_row .stat_attribute_value > .stat_attribute_value_item:not(:last-child) {
  padding-bottom: 1rem;
  border-bottom: 2px solid #eee;
}

.stat_attributes .stat_attribute_row .stat_attribute_value .stat_score_bar {
  position: relative;
  text-align: center;
  justify-content: center;
  background-color: #f7f7f7;
  font-size: .8rem;
}

.stat_attributes .stat_attribute_row .stat_attribute_value .stat_score_bar .stat_score_progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding-left: .3rem;
}

.stat_attributes .stat_attribute_row .stat_attribute_value .stat_score_bar .stat_execution_score_number {
  position: relative;
}

.quiz_stats_box .stat_score_bar {
  background-color: #fff !important;
}

.product_attribute_row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  border-top: 5px solid #f2f2f2;
}

.product_attribute_row .product_attribute_title {
  width: 50%;
  padding: 15px;
  box-sizing: border-box;
  background-color: #fff;
}

.product_attribute_row .product_attribute_value {
  width: 50%;
  padding: 15px;
  box-sizing: border-box;
  font-weight: 800;
  font-size: 110%;
  background-color: #f7f7f7;
}

.product_attribute_row .product_attribute_value > .product_attribute_value_item:not(:first-child) {
  padding-top: 1rem;
}

.product_attribute_row .product_attribute_value > .product_attribute_value_item:not(:last-child) {
  padding-bottom: 1rem;
  border-bottom: 2px solid #eee;
}

.product_attribute_row.dense .product_attribute_title, .product_attribute_row.dense .product_attribute_value {
  padding: 7px 15px;
}

.product_link_row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  border-top: 5px solid #f2f2f2;
}

.product_link_row .product_link_title {
  width: 50%;
  padding: 15px;
  box-sizing: border-box;
}

.product_link_row .product_link_value {
  width: 50%;
  padding: 15px;
  box-sizing: border-box;
  font-weight: 800;
  font-size: 110%;
  background-color: #f7f7f7;
}

.product_attribute_value > .product_attribute_value_item:not(:first-child), .product_link_value > .product_attribute_value_item:not(:first-child) {
  padding-top: 1rem;
}

.product_attribute_value > .product_attribute_value_item:not(:last-child), .product_link_value > .product_attribute_value_item:not(:last-child) {
  padding-bottom: 1rem;
  border-bottom: 2px solid #eee;
}

.image_link_row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  border-top: 5px solid #f2f2f2;
}

.image_link_row .image_link_title {
  width: 50%;
  padding: 15px;
  box-sizing: border-box;
}

.image_link_row .image_link_value {
  width: 50%;
  padding: 15px;
  box-sizing: border-box;
  font-weight: 800;
  font-size: 110%;
  background-color: #f7f7f7;
}

.image_link_row .image_link_value > .product_attribute_value_item:not(:first-child) {
  padding-top: 1rem;
}

.image_link_row .image_link_value > .product_attribute_value_item:not(:last-child) {
  padding-bottom: 1rem;
  border-bottom: 2px solid #eee;
}

.quiz_presentation {
  width: 100%;
  max-width: 800px;
  padding: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  text-align: left;
  color: #fff;
}

.quiz_presentation .quiz_presentation_image {
  width: 7rem;
  height: 7rem;
  margin-right: 1.5rem;
  border-radius: 3.5rem;
  background-size: cover;
  background-position: center center;
  color: #fff;
  line-height: 7;
  text-align: center;
}

.quiz_presentation .quiz_presentation_text {
  width: calc(100% - 8.5rem);
  padding-top: 1rem;
}

.quiz_presentation .quiz_presentation_text .quiz_presentation_title {
  margin-bottom: .75rem;
  text-align: left !important;
  padding: 0 !important;
}

.quiz_presentation .quiz_presentation_text .quiz_presentation_description {
  margin-bottom: .75rem;
}

.quiz_carousel .quiz_carousel_box {
  width: 100%;
  margin-right: 10px;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_question {
  background-color: #fff;
  padding: 30px;
  text-align: center;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers {
  padding: 30px;
  background-color: #f7f7f7;
  text-align: center;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_answers_box {
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_answers_box .quiz_question_label_radio {
  overflow: hidden;
  position: relative;
  margin: .5rem 1rem;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_answers_box .quiz_question_label_radio .circle {
  display: inline-block;
  vertical-align: middle;
  width: 1.6rem;
  height: 1.6rem;
  border: .2rem solid #ddd;
  box-sizing: border-box;
  border-radius: .8rem;
  margin-right: .2rem;
  position: relative;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_answers_box input[type=radio] {
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  width: 1px;
  height: 1px;
  position: absolute;
  left: -1px;
  top: -1px;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_answers_box input[type=radio]:checked + .quiz_question_label_radio .circle::after {
  content: '';
  position: absolute;
  top: .15rem;
  left: .15rem;
  width: .9rem;
  height: .9rem;
  border-radius: .45rem;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_answers_box .quiz_question_label_check {
  overflow: hidden;
  position: relative;
  margin: .5rem 1rem;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_answers_box .quiz_question_label_check .square {
  display: inline-block;
  vertical-align: middle;
  width: 1.6rem;
  height: 1.6rem;
  border: .2rem solid #ddd;
  box-sizing: border-box;
  margin-right: .2rem;
  position: relative;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_answers_box input[type=checkbox] {
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  width: 1px;
  height: 1px;
  position: absolute;
  left: -1px;
  top: -1px;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_answers_box input[type=checkbox]:checked + .quiz_question_label_check .square::after {
  content: '';
  position: absolute;
  top: .15rem;
  left: .15rem;
  width: .9rem;
  height: .9rem;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_answer_status {
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  margin: 1rem 0;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_answer_status.ok {
  color: #06d6a0;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_answer_status.error {
  color: #e9724c;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_pill {
  padding: 1rem 2rem;
  max-width: 600px;
  box-sizing: border-box;
  margin: 1rem auto;
  background-color: #fff;
  text-align: center;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_pill.ok {
  background-color: #d6f5d6;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_pill.error {
  background-color: #f9dcd2;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_pill .quiz_carousel_pill_title {
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: .5rem;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_question_continue {
  margin-top: 1.5rem;
  padding: .75rem 1.5rem;
  color: #fff;
  font-weight: 800;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers.results h4 {
  color: #fff;
}

ul.qa_box_opened_list {
  width: 101.5%;
  list-style: none;
  margin: 0 -.75%;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

ul.qa_box_opened_list li {
  width: 100%;
  padding: 0 .75%;
  box-sizing: border-box;
}

ul.qa_box_opened_list li .qa_box_opened_box {
  background-color: #fff;
}

ul.qa_box_opened_list li .qa_box_opened_box button.box_opened_question {
  width: 100%;
  padding: 25px 25px 0 25px;
  box-sizing: border-box;
  background-color: #fff;
  font-size: 1.7rem;
  font-weight: 400;
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

ul.qa_box_opened_list li .qa_box_opened_box button.box_opened_question i {
  position: absolute;
  top: 50%;
  right: 25px;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: none;
}

ul.qa_box_opened_list li .qa_box_opened_box .box_opened_answer {
  padding: 25px;
  text-align: left;
}

ul.qa_box_closed_list {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: flex-start;
}

ul.qa_box_closed_list li {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

ul.qa_box_closed_list li.active::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 3px;
}

ul.qa_box_closed_list li button.box_closed_question {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 1.1rem;
  font-weight: 800;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
}

ul.qa_box_closed_list li button.box_closed_question i {
  position: absolute;
  top: 50%;
  right: 25px;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: none;
}

.qa_box_closed_menu_mobile_wrapper {
  position: relative;
  z-index: 100;
  display: none;
}

.qa_box_closed_menu_mobile_wrapper .qa_box_closed_menu_mobile button.qa_box_closed_menu_mobile_toggle {
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
  font-size: 1.3rem;
  font-weight: 800;
  text-align: left;
  background-color: #fff;
  position: relative;
}

.qa_box_closed_menu_mobile_wrapper .qa_box_closed_menu_mobile button.qa_box_closed_menu_mobile_toggle i {
  position: absolute;
  top: 50%;
  right: 25px;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.qa_box_closed_menu_mobile_wrapper .qa_box_closed_menu_mobile button.qa_box_closed_menu_mobile_toggle i::before {
  content: "\f067";
}

.qa_box_closed_menu_mobile_wrapper .qa_box_closed_menu_mobile ul.qa_box_closed_list_mobile {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
  -webkit-box-shadow: 0 7px 7px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 7px 7px rgba(0, 0, 0, 0.1);
  box-shadow: 0 7px 7px rgba(0, 0, 0, 0.1);
}

.qa_box_closed_menu_mobile_wrapper .qa_box_closed_menu_mobile ul.qa_box_closed_list_mobile li {
  margin: 0;
}

.qa_box_closed_menu_mobile_wrapper .qa_box_closed_menu_mobile ul.qa_box_closed_list_mobile li button {
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
  font-size: 1.3rem;
  font-weight: 800;
  text-align: left;
  background-color: #fff;
}

.qa_box_closed_menu_mobile_wrapper.fixed .qa_box_closed_menu_mobile {
  position: fixed;
  top: 0;
  left: auto;
}

.qa_box_closed_menu_mobile_wrapper.fixed .qa_box_closed_menu_mobile button.qa_box_closed_menu_mobile_toggle {
  -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
}

.qa_box_closed_menu_mobile_wrapper.active .qa_box_closed_menu_mobile button.qa_box_closed_menu_mobile_toggle i::before {
  content: "\f068";
}

.qa_box_closed_menu_mobile_wrapper.active .qa_box_closed_menu_mobile ul.qa_box_closed_list_mobile {
  display: block;
}

.box_closed_answer {
  display: none;
  background-color: #fff;
  margin-bottom: 1rem;
}

.box_closed_answer.active {
  display: block;
}

#page_sections_nav {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
}

#page_sections_nav .section_nav_button {
  display: inline-block;
  vertical-align: top;
  width: calc(50% - 5px);
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
}

#page_sections_nav .section_nav_button:hover:not(.inactive) {
  background-color: #f2f2f2;
  color: inherit;
}

#page_sections_nav .section_nav_button.inactive {
  cursor: default;
  opacity: .5;
}

#page_sections_nav .section_nav_button .section_nav_icon {
  width: 5rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 800;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#page_sections_nav .section_nav_button .section_nav_label {
  width: calc(100% - 5rem);
  padding: 2rem;
  box-sizing: border-box;
  font-weight: 800;
}

#page_sections_nav .section_nav_button.prev .section_nav_label {
  text-align: left;
}

#page_sections_nav .section_nav_button.next .section_nav_label {
  text-align: right;
}

.technologies_table_box {
  background-color: #fff;
  margin-top: .5rem;
  padding: 2rem;
}

.technologies_table_box h4 {
  text-align: center;
}

.technologies_table_box .technologies_table .technologies_table_row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
  width: 100%;
  margin-top: 5px;
  background: #f9f9f9;
}

.technologies_table_box .technologies_table .technologies_table_row:hover {
  background-color: #f2f2f2;
}

.technologies_table_box .technologies_table .technologies_table_row > * {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.technologies_table_box .technologies_table .technologies_table_row .technology_table_image {
  width: 70px;
  height: 70px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.technologies_table_box .technologies_table .technologies_table_row .technology_table_title {
  width: calc(100% - 100px);
  padding: .5rem 1rem;
  font-weight: 800;
  text-transform: uppercase;
}

.technologies_table_box .technologies_table .technologies_table_row .technology_table_execution_score_bar {
  width: 100px;
  position: relative;
  text-align: center;
  justify-content: center;
  font-size: .8rem;
}

.technologies_table_box .technologies_table .technologies_table_row .technology_table_execution_score_bar .technology_table_execution_score_progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding-left: .3rem;
}

.technologies_table_box .technologies_table .technologies_table_row .technology_table_execution_score_bar .technology_table_execution_score_number {
  position: relative;
}

@media screen and (max-width: 991px) {
  #product_data_sheet {
    flex-wrap: wrap;
  }
  #product_data_sheet #product_menu_column {
    width: 100%;
    position: relative;
  }
  #product_data_sheet #product_menu_column #product_menu_fixed {
    position: initial;
    width: 100%;
    padding: 3rem 3rem 1rem 3rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }
  #product_data_sheet #product_menu_column #product_menu_fixed::before, #product_data_sheet #product_menu_column #product_menu_fixed::after {
    display: none;
  }
  #product_data_sheet #product_menu_column #product_menu_fixed img#product_menu_logo {
    width: 11rem;
  }
  #product_data_sheet #product_menu_column #product_menu_fixed .product_page_return {
    position: absolute;
    top: 0;
    left: 0;
    padding: .3rem 3rem;
    text-align: left;
    color: #fff;
    margin-top: 0;
  }
  #product_data_sheet #product_menu_column #product_menu_fixed .navbar-collapse {
    /*position:absolute;
          top:100%;
          left: 0;*/
    width: 100%;
  }
  #product_data_sheet #product_menu_column #product_menu_fixed .navbar-collapse ul#product_menu {
    margin: 0;
    padding: 1rem 0;
    box-sizing: border-box;
    background: #fff;
  }
  #product_data_sheet #product_menu_column #product_menu_fixed .navbar-collapse ul#product_menu li.divider {
    margin: 10px 0 !important;
  }
  #product_data_sheet #product_body {
    width: 100%;
  }
  ul.qa_box_opened_list li .qa_box_opened_box button.box_opened_question {
    padding: 2.5vw 2.5vw 0 2.5vw;
  }
  ul.qa_box_opened_list li .qa_box_opened_box .box_opened_answer {
    padding: 2.5vw;
    overflow: hidden;
  }
  button.box_closed_question {
    padding: 2.5vw;
  }
}

@media screen and (max-width: 768px) {
  #product_data_sheet #product_menu_column #product_menu_fixed .navbar-collapse ul#product_menu li.divider::before {
    content: '';
    display: block;
    height: 1px;
    background: #ddd;
  }
  .product_tab_template1, .product_tab_template2 {
    padding: 1.5% 3%;
    flex-wrap: wrap;
  }
  .product_tab_template1 .product_tab_template1_boxleft, .product_tab_template1 .product_tab_template1_boxright, .product_tab_template1 .product_tab_template2_boxleft, .product_tab_template1 .product_tab_template2_boxright, .product_tab_template2 .product_tab_template1_boxleft, .product_tab_template2 .product_tab_template1_boxright, .product_tab_template2 .product_tab_template2_boxleft, .product_tab_template2 .product_tab_template2_boxright {
    width: 100%;
    margin: 1.5% 0;
  }
  .product_tab_template1 .product_tab_template1_boxleft video, .product_tab_template1 .product_tab_template1_boxright video, .product_tab_template1 .product_tab_template2_boxleft video, .product_tab_template1 .product_tab_template2_boxright video, .product_tab_template2 .product_tab_template1_boxleft video, .product_tab_template2 .product_tab_template1_boxright video, .product_tab_template2 .product_tab_template2_boxleft video, .product_tab_template2 .product_tab_template2_boxright video {
    height: 52.8vw;
  }
  .product_retail_book {
    height: 90vh;
  }
}

@media screen and (max-width: 540px) {
  ul.qa_box_opened_list {
    flex-wrap: wrap;
  }
  ul.qa_box_opened_list li .qa_box_opened_box {
    margin-bottom: 5px;
  }
  ul.qa_box_opened_list li .qa_box_opened_box button.box_opened_question, ul.qa_box_opened_list li .qa_box_opened_box button.box_closed_question {
    width: 100%;
    height: auto;
    padding: 4.6vw;
    text-align: left;
    background-color: #eee;
  }
  ul.qa_box_opened_list li .qa_box_opened_box button.box_opened_question i, ul.qa_box_opened_list li .qa_box_opened_box button.box_closed_question i {
    display: block;
    right: 4.6vw;
  }
  ul.qa_box_opened_list li .qa_box_opened_box button.box_opened_question i::before, ul.qa_box_opened_list li .qa_box_opened_box button.box_closed_question i::before {
    content: "\f067";
  }
  ul.qa_box_opened_list li .qa_box_opened_box .box_opened_answer {
    height: 0;
    padding: 0 4.6vw;
    transition: padding 300ms ease 0ms;
  }
  ul.qa_box_opened_list li.active .qa_box_opened_box button.box_opened_question i::before {
    content: "\f068";
  }
  ul.qa_box_opened_list li.active .qa_box_opened_box .box_opened_answer {
    height: auto;
    padding-top: 4.6vw;
    padding-bottom: 4.6vw;
  }
  .qa_box_closed_list {
    display: none !important;
  }
  .qa_box_closed_menu_mobile_wrapper {
    display: block;
  }
  .qa_box_closed_menu_mobile_wrapper .qa_box_closed_menu_mobile button.qa_box_closed_menu_mobile_toggle {
    padding: 4.6vw;
  }
  .qa_box_closed_menu_mobile_wrapper .qa_box_closed_menu_mobile button.qa_box_closed_menu_mobile_toggle i {
    right: 4.6vw;
  }
  .qa_box_closed_menu_mobile_wrapper .qa_box_closed_menu_mobile ul.qa_box_closed_list_mobile li button {
    padding: 4.6vw;
  }
  #page_sections_nav .section_nav_button .section_nav_icon {
    width: 3rem;
  }
  #page_sections_nav .section_nav_button .section_nav_label {
    width: calc(100% - 3rem);
    padding: 1.5rem;
  }
}

.ace-nav > li.light-blue > a {
  background-color: #DDB800 !important;
}

#news_wrapper {
  padding: 1.5%;
  background-color: #f2f2f2;
}

#news_wrapper > h1 {
  text-align: center;
}

#news_wrapper .news_box {
  padding: 1.5rem;
  background-color: #fff;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}

#news_wrapper .news_box .news_box_image {
  width: 5rem;
}

#news_wrapper .news_box .news_box_image .news_box_image_image {
  height: 5rem;
  background-size: cover;
  background-position: center center;
  background-color: #eee;
}

#news_wrapper .news_box .news_box_text {
  width: calc(100% - 6.5rem);
}

#news_wrapper .news_box .news_box_text h2 {
  font-size: 1.4rem;
  font-weight: 800;
  margin-bottom: .5rem;
  padding: 0;
}

#news_wrapper .news_box .news_box_text .news_box_date {
  font-style: italic;
  margin: .5rem 0;
}

#news_wrapper .news_box .news_box_text .news_box_description_wrapper .news_box_preview {
  margin-top: .5rem;
}

#news_wrapper .news_box .news_box_text .news_box_description_wrapper .news_box_preview .news_box_preview_inner {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#news_wrapper .news_box .news_box_text .news_box_description_wrapper .news_box_preview .news_box_expand {
  margin-top: .5rem;
  text-align: right;
}

#news_wrapper .news_box .news_box_text .news_box_description_wrapper .news_box_preview .news_box_expand button {
  font-weight: 700;
  font-size: .9rem;
}

#news_wrapper .news_box .news_box_text .news_box_description_wrapper .news_box_preview .news_box_expand button i {
  margin-left: .5rem;
}

#news_wrapper .news_box .news_box_text .news_box_description_wrapper .news_box_description {
  margin-top: .5rem;
  display: none;
}

#news_wrapper .news_box .news_box_text .news_box_description_wrapper .news_box_description .news_box_close {
  margin: -2rem 0 .5rem 0;
  text-align: right;
}

#news_wrapper .news_box .news_box_text .news_box_description_wrapper .news_box_description .news_box_close button {
  font-weight: 700;
  font-size: .9rem;
}

#news_wrapper .news_box .news_box_text .news_box_description_wrapper .news_box_description .news_box_close button i {
  margin-left: .5rem;
}

#news_wrapper .news_box.active .news_box_preview {
  display: none;
}

#news_wrapper .news_box.active .news_box_description {
  display: block !important;
}

#news_wrapper #news_pagination {
  margin-top: 15px;
  text-align: center;
}

.title_stripe {
  background-color: #DDB800;
  text-align: center;
  padding: 2rem;
  color: #fff;
  font-weight: 800;
}

.default_content {
  background-color: #fff;
  padding: 2rem;
}

/*********************
*     STATISTICHE    *
*********************/
#stats_wrapper {
  background-color: #f2f2f2;
}

#stats_wrapper #stats_inner {
  position: relative;
}

#stats_wrapper #stats_inner #stats_breadcrumb {
  background: #fff;
  padding: 1.5% 5%;
  border-top: 1px solid #eee;
}

#stats_wrapper #stats_inner #stats_breadcrumb .stats_breadcrumb_item {
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 800;
  margin-right: 20px;
}

#stats_wrapper #stats_inner #stats_breadcrumb .stats_breadcrumb_item:not(:last-child)::after {
  content: "\f054";
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  margin-left: 20px;
  margin-bottom: 5px;
  font-size: .9rem;
  display: inline-block;
  vertical-align: middle;
  color: #333;
}

#stats_wrapper #stats_inner .stats_content {
  padding: 3%;
  position: relative;
}

#stats_wrapper #stats_inner .stats_content .stats_content_row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin: 0 -12px;
}

#stats_wrapper #stats_inner .stats_content .stats_content_row .stats_box {
  background-color: #fff;
  padding: 20px 2.14rem;
  width: calc(33.33333% - 24px);
  margin: 0 12px 24px 12px;
}

#stats_wrapper #stats_inner .stats_content .stats_content_row .stats_box .stats_box_title {
  font-size: 1.3rem;
  font-weight: 800;
  margin-bottom: 20px;
  text-transform: uppercase;
}

#stats_wrapper #stats_inner .stats_content .stats_content_row .stats_box .stats_box_content {
  margin: 0 -2.14rem;
}

#stats_wrapper #stats_inner .stats_content .stats_content_row .stats_box .stats_box_content .stats_box_content_title {
  font-size: 1.1rem;
  font-weight: 800;
  text-transform: uppercase;
  margin: 0 2.14rem 5px 2.14rem;
}

#stats_wrapper #stats_inner .stats_content .stats_content_row .stats_box .stats_box_content .stats_box_events {
  width: 100%;
  table-layout: fixed;
}

#stats_wrapper #stats_inner .stats_content .stats_content_row .stats_box .stats_box_content .stats_box_events:not(:last-child) {
  margin-bottom: 20px;
}

#stats_wrapper #stats_inner .stats_content .stats_content_row .stats_box .stats_box_content .stats_box_events > tbody > tr.odd {
  /*background-color: #f2f2f2;*/
}

#stats_wrapper #stats_inner .stats_content .stats_content_row .stats_box .stats_box_content .stats_box_events > tbody > tr > td {
  font-size: 1.1rem;
  padding: 5px 10px;
}

#stats_wrapper #stats_inner .stats_content .stats_content_row .stats_box .stats_box_content .stats_box_events > tbody > tr > td.key_td {
  text-transform: uppercase;
  padding-left: 2.14rem;
  width: 70%;
}

#stats_wrapper #stats_inner .stats_content .stats_content_row .stats_box .stats_box_content .stats_box_events > tbody > tr > td.value_td {
  font-weight: 700;
  width: 30%;
  padding-right: 2.14rem;
}

#stats_wrapper #stats_inner .stats_content .stats_content_row .stats_box .stats_box_content .stats_box_events > tbody > tr > td .stats_box_details {
  width: 100%;
  table-layout: fixed;
}

#stats_wrapper #stats_inner .stats_content .stats_content_row .stats_box .stats_box_content .stats_box_events > tbody > tr > td .stats_box_details > tbody > tr > td {
  font-size: 1rem;
  padding: 0 10px 5px 10px;
}

#stats_wrapper #stats_inner .stats_content .stats_content_row .stats_box .stats_box_content .stats_box_events > tbody > tr > td .stats_box_details > tbody > tr > td.key_td {
  text-transform: uppercase;
  width: 71%;
  padding-left: 2.14rem;
}

#stats_wrapper #stats_inner .stats_content .stats_content_row .stats_box .stats_box_content .stats_box_events > tbody > tr > td .stats_box_details > tbody > tr > td.value_td {
  font-weight: 700;
  width: 29%;
  padding-right: 2.14rem;
}

#stats_wrapper #stats_inner .stats_content .stats_content_row .stats_box .stats_box_content .stats_box_events > tbody > tr:not(:first-child) > td.key_td, #stats_wrapper #stats_inner .stats_content .stats_content_row .stats_box .stats_box_content .stats_box_events > tbody > tr:not(:first-child) > td.value_td {
  border-top: 1px solid #eee;
}

#stats_wrapper #stats_inner .stats_content .stats_content_row .stats_box .stats_box_btns {
  margin-top: 30px;
  text-align: right;
}

#stats_wrapper #stats_inner .stats_content .stats_content_row .stats_box .stats_box_btns > button {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
  color: inherit;
  text-transform: uppercase;
}

#stats_wrapper #stats_inner .stats_content .stats_content_row .stats_box .stats_box_btns > button i {
  margin-left: 5px;
}

#stats_wrapper #stats_inner .stats_content .stats_content_row .no_stats {
  width: 100%;
  text-align: center;
  font-style: italic;
}

#stats_wrapper #stats_inner #stats_loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#stats_wrapper #stats_inner #stats_loading i {
  margin-right: 5px;
}

.stats_details {
  background-color: #fff;
  border-top: 1px solid #eee;
}

.stats_details .stats_details_title {
  padding: 1.14rem 4.28rem 1.14rem 2.14rem;
  border-bottom: 1px solid #eee;
  position: relative;
}

.stats_details .stats_details_title .title {
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 800;
}

.stats_details .stats_details_title .subtitle {
  font-size: 1.2rem;
}

.stats_details .stats_details_title .stats_details_close {
  position: absolute;
  top: 50%;
  right: 2.14rem;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 1.3rem;
}

.stats_details .stats_details_content {
  padding: 2.14rem;
}

.stats_details .stats_details_content .stats_details_tab {
  padding: 1px 0;
}

.stats_details .stats_details_content .stats_details_tab .stats_details_tab_btn {
  font-size: 1.1rem;
  width: 100%;
  padding: 10px 2.14rem;
  text-transform: uppercase;
  font-weight: 700;
  text-align: left;
}

.stats_details .stats_details_content .stats_details_tab .stats_details_tab_btn i {
  margin-left: .5rem;
}

.stats_details .stats_details_content .stats_details_tab .stats_details_table {
  width: 100%;
  table-layout: fixed;
  display: none;
}

.stats_details .stats_details_content .stats_details_tab .stats_details_table > thead > tr > th {
  font-size: 1.1rem;
  padding: 10px 2.14rem;
  text-transform: uppercase;
}

.stats_details .stats_details_content .stats_details_tab .stats_details_table > tbody > tr > td {
  font-size: 1.1rem;
  padding: 10px 30px;
  border-top: 1px solid #eee;
  vertical-align: middle;
}

.stats_details .stats_details_content .stats_details_tab .stats_details_table > tbody > tr > td.key_td {
  text-transform: uppercase;
}

.stats_details .stats_details_content .stats_details_tab .stats_details_table > tbody > tr > td.value_td {
  font-weight: 700;
}

.stats_details .stats_details_content .stats_details_tab .stats_details_table > tbody > tr > td.value_td .stat_score_bar {
  position: relative;
  text-align: center;
  justify-content: center;
  background-color: #f7f7f7;
  font-size: 1rem;
}

.stats_details .stats_details_content .stats_details_tab .stats_details_table > tbody > tr > td.value_td .stat_score_bar .stat_score_progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding-left: .3rem;
}

.stats_details .stats_details_content .stats_details_tab .stats_details_table > tbody > tr > td.value_td .stat_score_bar .stat_execution_score_number {
  position: relative;
  z-index: 2;
}

.stats_details .stats_details_content .stats_details_tab.active {
  padding-top: 0;
  margin-bottom: 1rem;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}

.stats_details .stats_details_content .stats_details_tab.active:not(:first-child) {
  margin-top: 1rem;
}

.stats_details .stats_details_content .stats_details_tab.active .stats_details_tab_btn i {
  opacity: 0;
}

.stats_details .stats_details_content .stats_details_tab.active .stats_details_table {
  display: table;
}

#challenge_rankings {
  width: calc(100% + 60px);
  margin-left: -30px;
  margin-bottom: 25px;
}

#challenge_rankings > thead > tr > th {
  font-size: 1.1rem;
  padding: 5px 30px;
  text-transform: uppercase;
}

#challenge_rankings > tbody > tr > td {
  font-size: 1.1rem;
  padding: 5px 30px;
  border-top: 1px solid #eee;
  vertical-align: middle;
}

#challenge_rankings > tbody > tr > td.key_td {
  text-transform: uppercase;
}

#challenge_rankings > tbody > tr > td.value_td {
  font-weight: 700;
}

#challenge_rankings > tbody > tr > td .pos_label {
  display: inline-block;
  vertical-align: middle;
  border: 2px solid #333;
  width: 24px;
  height: 24px;
  line-height: 20px;
  border-radius: 12px;
  text-align: center;
  font-weight: 800;
}

@media screen and (max-width: 1170px) {
  #stats_wrapper #stats_inner .stats_content .stats_content_row .stats_box {
    width: calc(50% - 24px);
  }
}

@media screen and (max-width: 540px) {
  #stats_wrapper #stats_inner .stats_content .stats_content_row .stats_box {
    width: calc(100% - 24px);
  }
}

#app_footer {
  text-align: center;
  padding: 2rem 4rem;
  color: #fff;
}

#app_footer_icons {
  margin-top: 1rem;
}

#app_footer_icons > a {
  display: inline-block;
  vertical-align: middle;
  margin: 0 .5rem;
  color: inherit;
}

#app_footer_icons > a:hover {
  color: #DDB800;
}

#app_footer_icons > a > img {
  width: 2.85rem;
}

#app_footer_font {
  margin-top: 1rem;
}

#app_footer_font > a {
  display: inline-block;
  vertical-align: middle;
  margin: 0 .5rem;
  color: inherit;
  font-size: 1.5rem;
}
